@import "~datatables.net-bs4/css/dataTables.bootstrap4.min.css";
@import "~datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";

/* CUSTOM BOOTSTRAP VARIABLE OVERRIDES */
$primary: #548b31;
$success: $primary;

a.btn-success,
a.btn-success:hover,
a.btn-success:active {
    color: #ffffff !important;
}

.media-library {
    max-width: 90% !important;

    ul {
        li {
            &.list-inline-item {
                margin-bottom: 0.5rem;

                &:not(:last-child) {
                    margin-right: 0.4rem !important;
                }

                .file-preview {
                    background: #F6f6f6;
                    border: 1px solid #CCC;

                    .file-name {
                        display: blocK;
                        overflow: hidden;
                        max-width: 120px;
                        white-space: nowrap;
                        text-overflow: ellipsis;

                        &:hover {
                            white-space: initial;
                            text-overflow: initial;
                            overflow: initial;
                        }
                    }

                    .file-preview-icon {
                        max-width: 120px;
                        max-height: 120px;
                        font-size: 5rem;
                        margin: 0 auto;
                        color: #447100;
                    }
                }
            }
        }
    }

    .lib-table-wrapper {
        overflow-x: scroll;
    }

    table {
        width: 100% !important;

        thead {
            th {
                width: auto !important;
            }
        }
    }
}

.files-upload-popup-0 {
    background: none !important;
    border: none !important;
}

/* Import must be after overrides */
$fa-font-display: swap !default;
@import '~bootstrap/scss/bootstrap';
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";

.cms-g-holder {
    width: 100%;
    height: 400px;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.cms-footer {
    background-color: #fff;
    border-top: 1px solid $primary;
    position: fixed;
    padding-top: calc(1rem + 15px);
    padding-bottom: calc(1rem + 15px);
    padding-left: 3rem;
    left: 225px;
    bottom: 0%;
    width: calc(100% - 225px);
    z-index: 999;

    @media(max-width: 575px) {
        left: 0;
        width: 100%;
    }

    &.sidebar-toggled {
        left: 0;
        width: 100%;
    }

    &.placed-static {
        position: absolute;
        width: calc(100% + 15px + 15px + 3rem);
        left: -33px;
        // z-index: 999;
        padding-top: calc(1rem + 5px);
        padding-bottom: calc(1rem + 5px);
    }
}

.pb-80 {
    padding-bottom: 80px;
}

form .tab-content {
    .cms-footer {
        padding-top: calc(1rem + 5px);
        padding-bottom: 5px;
    }
}

.media_library_overlay_0 {
    height: 100% !important;
}

form .half-row {
    display: inline-block;
    width: calc(50% - 10px);

    &:first-child {
        margin-right: 15px;
    }
}

.nav-right-side {
    position: relative;

    .lang-wrap {
        margin-right: 15px;
        position: relative;

        .dropdown-menu {
            top: 20px !important;
        }

        a {
            line-height: 30px;
            text-decoration: none;
            text-transform: capitalize;
            font-size: 13px;
            font-weight: 500;
            color: #fff;

            &.dropdown-toggle::after {
                border: 0 !important;
                content: "\f078" !important;

                vertical-align: 0 !important;
                margin-left: 5px !important;
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                font-size: 10px;
            }

            img {
                width: 21px;
                margin-right: 10px;
            }

            &:hover {
                opacity: 0.7;
            }
        }

        .dropdown-item {
            color: #000;
        }
    }

    .fa-moon {
        margin-left: 5px;
        font-size: 14px;
        top: 8px;
        color: rgba(255, 255, 255, 0.5);
    }

    .checkbox.checbox-switch {
        padding-left: 0;
    }

    .checkbox.checbox-switch label,
    .checkbox-inline.checbox-switch {
        display: inline-block;
        position: relative;
        padding-left: 0;
    }

    .checkbox.checbox-switch label input,
    .checkbox-inline.checbox-switch input {
        display: none;
    }

    .checkbox.checbox-switch label span,
    .checkbox-inline.checbox-switch span {
        width: 35px;
        border-radius: 20px;
        height: 16px;
        border: 1px solid #505050;
        background-color: #fff;
        box-shadow: rgb(223, 223, 223) 0px 0px 0px 0px inset;
        transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
    }

    .checkbox.checbox-switch label span:before,
    .checkbox-inline.checbox-switch span:before {
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #505050;
        content: " ";
        position: relative;
        left: 0px;
        transition: all 0.3s ease;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
        top: -1px;
    }

    .checkbox.checbox-switch label>input:checked+span:before,
    .checkbox-inline.checbox-switch>input:checked+span:before {
        left: 16px;
        top: -1px;
        background: #fff;
    }

    /* Switch Primary */
    .checkbox.checbox-switch.switch-primary label>input:checked+span,
    .checkbox-inline.checbox-switch.switch-primary>input:checked+span {
        background-color: #505050;
        border-color: rgb(0, 105, 217);
        /*box-shadow: rgb(0, 105, 217) 0px 0px 0px 8px inset;*/
        transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
    }

    .checkbox.checbox-switch.switch-primary label>input:checked:disabled+span,
    .checkbox-inline.checbox-switch.switch-primary>input:checked:disabled+span {
        background-color: red;
        border-color: red;
        /* box-shadow: rgb(109, 163, 221) 0px 0px 0px 8px inset;*/
        transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
    }
}

.media-library-row {
    margin-bottom: 0.5rem;

    .center-align {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        .dataTables_paginate.paging_simple_numbers {
            margin-bottom: 0 !important;
        }
    }
}

.cke_reset_all {
    &.cke_dialog_container {
        position: absolute;
        left: 0;
        right: 0;
        top: 60px;

        .cke_dialog_tabs {
            left: unset;
        }
    }
}